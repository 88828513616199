input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f1f1f1;
  border-radius: 52px;
  border: 1px solid #f1f1f1;
}
input[type=range]::-webkit-slider-thumb {
  /* box-shadow: 0px 0px 0px #FFFFFF;
  border: 1px solid #BFBFBF; */
  height: 19px;
  width: 19px;
  /* border-radius: 23px;
  background: #E8E8E8;*/
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;  



  background: #fff -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.05)));
  background: #fff -webkit-linear-gradient(transparent,rgba(0,0,0,.05));
  background: #fff linear-gradient(transparent,rgba(0,0,0,.05));
  
  opacity: 1;
  z-index: 2;
  border: none;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15), 0 0 0 1px rgba(34,36,38,.15) inset;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15), 0 0 0 1px rgba(34,36,38,.15) inset;
  /* width: 1.5rem;
  height: 1.5rem; */
  /* top: -.25rem;
  left: 0; */
  -webkit-transform: none;
  transform: none;
  border-radius: 500rem;
  -webkit-transition: left .3s ease;
  transition: left .3s ease;
}



input[type=range]:focus::-webkit-slider-runnable-track {
  xbackground: #FFFFFF;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #A6A6A6;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #FFFFFF;
  border: 1px solid #BFBFBF;
  height: 38px;
  width: 27px;
  border-radius: 23px;
  xbackground: #E8E8E8;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  xbackground: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  xbackground: #FFFFFF;
  border: 1px solid #A6A6A6;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  xbackground: #FFFFFF;
  border: 1px solid #A6A6A6;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #FFFFFF;
  border: 1px solid #BFBFBF;
  height: 38px;
  width: 27px;
  border-radius: 23px;
  xbackground: #E8E8E8;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  xbackground: #FFFFFF;
}
input[type=range]:focus::-ms-fill-upper {
  xbackground: #FFFFFF;
}