/*!
 * =====================================================
 * Photon v0.1.1
 * Copyright 2015 Connor Sears
 * Licensed under MIT (https://github.com/connors/proton/blob/master/LICENSE)
 *
 * v0.1.1 designed by @connors.
 * =====================================================
 */

 @charset "UTF-8";
 audio,
 canvas,
 progress,
 video {
   vertical-align: baseline;
 }
 
 audio:not([controls]) {
   display: none;
 }
 
 a:active,
 a:hover {
   outline: 0;
 }
 
 abbr[title] {
   border-bottom: 1px dotted;
 }
 
 b,
 strong {
   font-weight: bold;
 }
 
 dfn {
   font-style: italic;
 }
 
 h1 {
   font-size: 2em;
   margin: 0.67em 0;
 }
 
 small {
   font-size: 80%;
 }
 
 sub,
 sup {
   font-size: 75%;
   line-height: 0;
   position: relative;
   vertical-align: baseline;
 }
 
 sup {
   top: -0.5em;
 }
 
 sub {
   bottom: -0.25em;
 }
 
 pre {
   overflow: auto;
 }
 
 code,
 kbd,
 pre,
 samp {
   font-family: monospace, monospace;
   font-size: 1em;
 }
 
 button,
 input,
 optgroup,
 select,
 textarea {
   color: inherit;
   font: inherit;
   margin: 0;
 }
 
 input[type="number"]::-webkit-inner-spin-button,
 input[type="number"]::-webkit-outer-spin-button {
   height: auto;
 }
 
 input[type="search"] {
   -webkit-appearance: textfield;
   box-sizing: content-box;
 }
 
 input[type="search"]::-webkit-search-cancel-button,
 input[type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 
 fieldset {
   border: 1px solid #c0c0c0;
   margin: 0 2px;
   padding: 0.35em 0.625em 0.75em;
 }
 
 legend {
   border: 0;
   padding: 0;
 }
 
 table {
   border-collapse: collapse;
   border-spacing: 0;
 }
 
 td,
 th {
   padding: 0;
 }
/*  
 * {
   cursor: default;
   -webkit-user-drag: text;
   -webkit-user-select: none;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
 }
 
 html {
   height: 100%;
   width: 100%;
   overflow: hidden;
 }
 
 body {
   height: 100%;
   padding: 0;
   margin: 0;
   font-family: system, -apple-system, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, "Segoe UI", sans-serif;
   font-size: 13px;
   line-height: 1.6;
   color: #333;
   background-color: transparent;
 } */
/*  
 hr {
   margin: 15px 0;
   overflow: hidden;
   background: transparent;
   border: 0;
   border-bottom: 1px solid #ddd;
 }
 
 h1, h2, h3, h4, h5, h6 {
   margin-top: 20px;
   margin-bottom: 10px;
   font-weight: 500;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 } */
/*  
 h1 {
   font-size: 36px;
 }
 
 h2 {
   font-size: 30px;
 }
 
 h3 {
   font-size: 24px;
 }
 
 h4 {
   font-size: 18px;
 }
 
 h5 {
   font-size: 14px;
 }
 
 h6 {
   font-size: 12px;
 }
 
 .window {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   background-color: #fff;
 }
 
 .window-content {
   position: relative;
   overflow-y: auto;
   display: flex;
   flex: 1;
 }
  
 .selectable-text {
   cursor: text;
   -webkit-user-select: text;
 }
 
 .text-center {
   text-align: center;
 }
 
 .text-right {
   text-align: right;
 }
 
 .text-left {
   text-align: left;
 }
 
 .pull-left {
   float: left;
 }
 
 .pull-right {
   float: right;
 }
 
 .padded {
   padding: 10px;
 }
 
 .padded-less {
   padding: 5px;
 }
 
 .padded-more {
   padding: 20px;
 }
 
 .padded-vertically {
   padding-top: 10px;
   padding-bottom: 10px;
 }
 
 .padded-vertically-less {
   padding-top: 5px;
   padding-bottom: 5px;
 }
 
 .padded-vertically-more {
   padding-top: 20px;
   padding-bottom: 20px;
 }
 
 .padded-horizontally {
   padding-right: 10px;
   padding-left: 10px;
 }
 
 .padded-horizontally-less {
   padding-right: 5px;
   padding-left: 5px;
 }
 
 .padded-horizontally-more {
   padding-right: 20px;
   padding-left: 20px;
 }
 
 .padded-top {
   padding-top: 10px;
 }
 
 .padded-top-less {
   padding-top: 5px;
 }
 
 .padded-top-more {
   padding-top: 20px;
 }
 
 .padded-bottom {
   padding-bottom: 10px;
 }
 
 .padded-bottom-less {
   padding-bottom: 5px;
 }
 
 .padded-bottom-more {
   padding-bottom: 20px;
 }
 
 .sidebar {
   background-color: #f5f5f4;
 }
 
 .draggable {
   -webkit-app-region: drag;
 }
 
 .clearfix:before, .clearfix:after {
   display: table;
   content: " ";
 }
 .clearfix:after {
   clear: both;
 }
 */
 .btn {
   display: inline-block;
   padding: 3px 8px;
   margin-bottom: 0;
   font-size: 12px;
   line-height: 1.4;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   cursor: default;
   background-image: none;
   border: 1px solid transparent;
   border-radius: 4px;
   xbox-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
   -webkit-app-region: no-drag;
   min-height: 27px;
 }
 .btn:focus {
   outline: none;
   box-shadow: none;
 }
 
 .btn-mini {
   padding: 2px 6px;
 }
 
 .btn-large {
   padding: 6px 12px;
 }
 
 .btn-form {
   padding-right: 20px;
   padding-left: 20px;
 }
 
 .btn-default {
   color: #333;
   /* border-top-color: #c2c0c2;
   border-right-color: #c2c0c2;
   border-bottom-color: #a19fa1;
   border-left-color: #c2c0c2; */
   border-color: #c2c0c2;
   background-color: #fcfcfc;
   
   /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fcfcfc), color-stop(100%, #f1f1f1));
   background-image: -webkit-linear-gradient(top, #fcfcfc 0%, #f1f1f1 100%);
   background-image: linear-gradient(to bottom, #fcfcfc 0%, #f1f1f1 100%); */
 }
 .btn-default:active {
   background-color: #ddd;
   background-image: none;
 }
 
 .btn-primary,
 .btn-positive,
 .btn-negative,
 .btn-warning {
   color: #fff;
   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
 }
 
 .btn-primary {
   border-color: #388df8;
   border-bottom-color: #0866dc;
   background-color: #6eb4f7;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6eb4f7), color-stop(100%, #1a82fb));
   background-image: -webkit-linear-gradient(top, #6eb4f7 0%, #1a82fb 100%);
   background-image: linear-gradient(to bottom, #6eb4f7 0%, #1a82fb 100%);
 }
 .btn-primary:active {
   background-color: #3e9bf4;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e9bf4), color-stop(100%, #0469de));
   background-image: -webkit-linear-gradient(top, #3e9bf4 0%, #0469de 100%);
   background-image: linear-gradient(to bottom, #3e9bf4 0%, #0469de 100%);
 }
 
 .btn-positive {
   border-color: #29a03b;
   border-bottom-color: #248b34;
   background-color: #5bd46d;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5bd46d), color-stop(100%, #29a03b));
   background-image: -webkit-linear-gradient(top, #5bd46d 0%, #29a03b 100%);
   background-image: linear-gradient(to bottom, #5bd46d 0%, #29a03b 100%);
 }
 .btn-positive:active {
   background-color: #34c84a;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #34c84a), color-stop(100%, #248b34));
   background-image: -webkit-linear-gradient(top, #34c84a 0%, #248b34 100%);
   background-image: linear-gradient(to bottom, #34c84a 0%, #248b34 100%);
 }
 
 .btn-negative {
   border-color: #fb2f29;
   border-bottom-color: #fb1710;
   background-color: #fd918d;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fd918d), color-stop(100%, #fb2f29));
   background-image: -webkit-linear-gradient(top, #fd918d 0%, #fb2f29 100%);
   background-image: linear-gradient(to bottom, #fd918d 0%, #fb2f29 100%);
 }
 .btn-negative:active {
   background-color: #fc605b;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc605b), color-stop(100%, #fb1710));
   background-image: -webkit-linear-gradient(top, #fc605b 0%, #fb1710 100%);
   background-image: linear-gradient(to bottom, #fc605b 0%, #fb1710 100%);
 }
 
 .btn-warning {
   border-color: #fcaa0e;
   border-bottom-color: #ee9d02;
   background-color: #fece72;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fece72), color-stop(100%, #fcaa0e));
   background-image: -webkit-linear-gradient(top, #fece72 0%, #fcaa0e 100%);
   background-image: linear-gradient(to bottom, #fece72 0%, #fcaa0e 100%);
 }
 .btn-warning:active {
   background-color: #fdbc40;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdbc40), color-stop(100%, #ee9d02));
   background-image: -webkit-linear-gradient(top, #fdbc40 0%, #ee9d02 100%);
   background-image: linear-gradient(to bottom, #fdbc40 0%, #ee9d02 100%);
 }
 
 .btn .ico {
   float: left;
   width: 14px;
   height: 14px;
   margin-top: 1px;
   margin-bottom: 1px;
   color: #737475;
   font-size: 14px;
   line-height: 1;
 }
 
 .btn .ico-text {
   margin-right: 5px;
 }
 
 .btn-dropdown:after {
   font-family: "photon-entypo";
   margin-left: 5px;
   content: "";
 }
 
 .btn-group {
   position: relative;
   display: inline-block;
   vertical-align: middle;
   -webkit-app-region: no-drag;
 }
 .btn-group .btn {
   position: relative;
   float: left;
 }
 .btn-group .btn:focus, .btn-group .btn:active {
   z-index: 2;
 }
 .btn-group .btn.active {
   z-index: 3;
 }
 
 .btn-group .btn + .btn,
 .btn-group .btn + .btn-group,
 .btn-group .btn-group + .btn,
 .btn-group .btn-group + .btn-group {
   margin-left: -1px;
 }
 .btn-group > .btn:first-child {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }
 .btn-group > .btn:last-child {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }
 .btn-group > .btn:not(:first-child):not(:last-child) {
   border-radius: 0;
 }
 .btn-group .btn + .btn {
   border-left: 1px solid #c2c0c2;
 }
 .btn-group .btn + .btn.active {
   border-left: 0;
 }
 .btn-group .active {
   color: #fff;
   border: 1px solid transparent;
   background-color: #6d6c6d;
   background-image: none;
 }
 .btn-group .active .ico {
   color: #fff;
 }
 
 .toolbar {
   min-height: 22px;
   box-shadow: inset 0 1px 0 #f5f4f5;
   background-color: #e8e6e8;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e8e6e8), color-stop(100%, #d1cfd1));
   background-image: -webkit-linear-gradient(top, #e8e6e8 0%, #d1cfd1 100%);
   background-image: linear-gradient(to bottom, #e8e6e8 0%, #d1cfd1 100%);
 }
 .toolbar:before, .toolbar:after {
   display: table;
   content: " ";
 }
 .toolbar:after {
   clear: both;
 }
 
 .toolbar-header {
   border-bottom: 1px solid #c2c0c2;
 }
 .toolbar-header .title {
   margin-top: 1px;
 }
 
 .toolbar-footer {
   border-top: 1px solid #c2c0c2;
   -webkit-app-region: drag;
 }
 
 .title {
   margin: 0;
   font-size: 12px;
   font-weight: 400;
   text-align: center;
   color: #555;
   cursor: default;
 }
 
 .toolbar-borderless {
   border-top: 0;
   border-bottom: 0;
 }
 
 .toolbar-actions {
   margin-top: 4px;
   margin-bottom: 3px;
   padding-right: 3px;
   padding-left: 3px;
   padding-bottom: 3px;
   -webkit-app-region: drag;
 }
 .toolbar-actions:before, .toolbar-actions:after {
   display: table;
   content: " ";
 }
 .toolbar-actions:after {
   clear: both;
 }
 .toolbar-actions > .btn,
 .toolbar-actions > .btn-group {
   margin-left: 4px;
   margin-right: 4px;
 }
 
 /* label {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 5px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
  */
 input[type="search"] {
   box-sizing: border-box;
 }
 
 input[type="radio"],
 input[type="checkbox"] {
   margin: 4px 0 0;
   line-height: normal;
 }
 
 .form-control {
  color: rgb(0, 0, 0);
   display: inline-block;
   width: 100%;
   min-height: 25px;
   padding: 5px 10px;
   font-size: 13px;
   line-height: 1.6;
   background-color: #fff;
   border: 1px solid #ddd;
   border-radius: 4px;
   outline: none;
 }
 .form-control:focus {
   border-color: #6db3fd;
   box-shadow: 3px 3px 0 #6db3fd, -3px -3px 0 #6db3fd, -3px 3px 0 #6db3fd, 3px -3px 0 #6db3fd;
 }
 
 textarea {
   height: auto;
 }
 
 .form-group {
   margin-bottom: 10px;
 }
/*  
 .radio,
 .checkbox {
   position: relative;
   display: block;
   margin-top: 10px;
   margin-bottom: 10px;
 }
 /* .radio label,
 .checkbox label {
   padding-left: 20px;
   margin-bottom: 0;
   font-weight: normal;
 } */ */
 
 .radio input[type="radio"],
 .radio-inline input[type="radio"],
 .checkbox input[type="checkbox"],
 .checkbox-inline input[type="checkbox"] {
   position: absolute;
   margin-left: -20px;
   margin-top: 4px;
 }
 
 .form-actions .btn {
   margin-right: 10px;
 }
 .form-actions .btn:last-child {
   margin-right: 0;
 }
 
 .pane-group {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   display: flex;
 }
 
 .pane {
   position: relative;
   overflow-y: auto;
   flex: 1;
   border-left: 1px solid #ddd;
 }
 .pane:first-child {
   border-left: 0;
 }
 
 .pane-sm {
   max-width: 220px;
   min-width: 150px;
 }
 
 .pane-mini {
   width: 80px;
   flex: none;
 }
 
 .pane-one-fourth {
   width: 25%;
   flex: none;
 }
 
 .pane-one-third {
   width: 33.3%;
 }
 
 img {
   -webkit-user-drag: text;
 }
 
 .img-circle {
   border-radius: 50%;
 }
 
 .img-rounded {
   border-radius: 4px;
 }
 
 .list-group {
   width: 100%;
   list-style: none;
   margin: 0;
   padding: 0;
 }
 .list-group * {
   margin: 0;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 
 .list-group-item {
   padding: 10px;
   font-size: 12px;
   color: #414142;
   border-top: 1px solid #ddd;
 }
 .list-group-item:first-child {
   border-top: 0;
 }
 .list-group-item.active, .list-group-item.selected {
   color: #fff;
   background-color: #116cd6;
 }
 
 .list-group-header {
   padding: 10px;
 }
 
 .media-object {
   margin-top: 3px;
 }
 
 .media-object.pull-left {
   margin-right: 10px;
 }
 
 .media-object.pull-right {
   margin-left: 10px;
 }
 
 .media-body {
   overflow: hidden;
 }
 
 .nav-group {
   font-size: 14px;
 }
 
 .nav-group-item {
   padding: 2px 10px 2px 25px;
   display: block;
   color: #333;
   text-decoration: none;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .nav-group-item:active, .nav-group-item.active {
   background-color: #dcdfe1;
 }
 .nav-group-item .ico {
   width: 19px;
   height: 18px;
   float: left;
   color: #737475;
   margin-top: -3px;
   margin-right: 7px;
   font-size: 18px;
   text-align: center;
 }
 
 .nav-group-title {
   margin: 0;
   padding: 10px 10px 2px;
   font-size: 12px;
   font-weight: 500;
   color: #666666;
 }
 
 @font-face {
   font-family: "photon-entypo";
   src: url("../fonts/photon-entypo.eot");
   src: url("../fonts/photon-entypo.eot?#iefix") format("eot"), url("../fonts/photon-entypo.woff") format("woff"), url("../fonts/photon-entypo.ttf") format("truetype");
   font-weight: normal;
   font-style: normal;
 }
 .ico:before {
   position: relative;
   display: inline-block;
   font-family: "photon-entypo";
   speak: none;
   font-size: 100%;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 .ico-note:before {
   content: '\e800';
 }
 
 /* '' */
 .ico-note-beamed:before {
   content: '\e801';
 }
 
 /* '' */
 .ico-music:before {
   content: '\e802';
 }
 
 /* '' */
 .ico-search:before {
   content: '\e803';
 }
 
 /* '' */
 .ico-flashlight:before {
   content: '\e804';
 }
 
 /* '' */
 .ico-mail:before {
   content: '\e805';
 }
 
 /* '' */
 .ico-heart:before {
   content: '\e806';
 }
 
 /* '' */
 .ico-heart-empty:before {
   content: '\e807';
 }
 
 /* '' */
 .ico-star:before {
   content: '\e808';
 }
 
 /* '' */
 .ico-star-empty:before {
   content: '\e809';
 }
 
 /* '' */
 .ico-user:before {
   content: '\e80a';
 }
 
 /* '' */
 .ico-users:before {
   content: '\e80b';
 }
 
 /* '' */
 .ico-user-add:before {
   content: '\e80c';
 }
 
 /* '' */
 .ico-video:before {
   content: '\e80d';
 }
 
 /* '' */
 .ico-picture:before {
   content: '\e80e';
 }
 
 /* '' */
 .ico-camera:before {
   content: '\e80f';
 }
 
 /* '' */
 .ico-layout:before {
   content: '\e810';
 }
 
 /* '' */
 .ico-menu:before {
   content: '\e811';
 }
 
 /* '' */
 .ico-check:before {
   content: '\e812';
 }
 
 /* '' */
 .ico-cancel:before {
   content: '\e813';
 }
 
 /* '' */
 .ico-cancel-circled:before {
   content: '\e814';
 }
 
 /* '' */
 .ico-cancel-squared:before {
   content: '\e815';
 }
 
 /* '' */
 .ico-plus:before {
   content: '\e816';
 }
 
 /* '' */
 .ico-plus-circled:before {
   content: '\e817';
 }
 
 /* '' */
 .ico-plus-squared:before {
   content: '\e818';
 }
 
 /* '' */
 .ico-minus:before {
   content: '\e819';
 }
 
 /* '' */
 .ico-minus-circled:before {
   content: '\e81a';
 }
 
 /* '' */
 .ico-minus-squared:before {
   content: '\e81b';
 }
 
 /* '' */
 .ico-help:before {
   content: '\e81c';
 }
 
 /* '' */
 .ico-help-circled:before {
   content: '\e81d';
 }
 
 /* '' */
 .ico-info:before {
   content: '\e81e';
 }
 
 /* '' */
 .ico-info-circled:before {
   content: '\e81f';
 }
 
 /* '' */
 .ico-back:before {
   content: '\e820';
 }
 
 /* '' */
 .ico-home:before {
   content: '\e821';
 }
 
 /* '' */
 .ico-link:before {
   content: '\e822';
 }
 
 /* '' */
 .ico-attach:before {
   content: '\e823';
 }
 
 /* '' */
 .ico-lock:before {
   content: '\e824';
 }
 
 /* '' */
 .ico-lock-open:before {
   content: '\e825';
 }
 
 /* '' */
 .ico-eye:before {
   content: '\e826';
 }
 
 /* '' */
 .ico-tag:before {
   content: '\e827';
 }
 
 /* '' */
 .ico-bookmark:before {
   content: '\e828';
 }
 
 /* '' */
 .ico-bookmarks:before {
   content: '\e829';
 }
 
 /* '' */
 .ico-flag:before {
   content: '\e82a';
 }
 
 /* '' */
 .ico-thumbs-up:before {
   content: '\e82b';
 }
 
 /* '' */
 .ico-thumbs-down:before {
   content: '\e82c';
 }
 
 /* '' */
 .ico-download:before {
   content: '\e82d';
 }
 
 /* '' */
 .ico-upload:before {
   content: '\e82e';
 }
 
 /* '' */
 .ico-upload-cloud:before {
   content: '\e82f';
 }
 
 /* '' */
 .ico-reply:before {
   content: '\e830';
 }
 
 /* '' */
 .ico-reply-all:before {
   content: '\e831';
 }
 
 /* '' */
 .ico-forward:before {
   content: '\e832';
 }
 
 /* '' */
 .ico-quote:before {
   content: '\e833';
 }
 
 /* '' */
 .ico-code:before {
   content: '\e834';
 }
 
 /* '' */
 .ico-export:before {
   content: '\e835';
 }
 
 /* '' */
 .ico-pencil:before {
   content: '\e836';
 }
 
 /* '' */
 .ico-feather:before {
   content: '\e837';
 }
 
 /* '' */
 .ico-print:before {
   content: '\e838';
 }
 
 /* '' */
 .ico-retweet:before {
   content: '\e839';
 }
 
 /* '' */
 .ico-keyboard:before {
   content: '\e83a';
 }
 
 /* '' */
 .ico-comment:before {
   content: '\e83b';
 }
 
 /* '' */
 .ico-chat:before {
   content: '\e83c';
 }
 
 /* '' */
 .ico-bell:before {
   content: '\e83d';
 }
 
 /* '' */
 .ico-attention:before {
   content: '\e83e';
 }
 
 /* '' */
 .ico-alert:before {
   content: '\e83f';
 }
 
 /* '' */
 .ico-vcard:before {
   content: '\e840';
 }
 
 /* '' */
 .ico-address:before {
   content: '\e841';
 }
 
 /* '' */
 .ico-location:before {
   content: '\e842';
 }
 
 /* '' */
 .ico-map:before {
   content: '\e843';
 }
 
 /* '' */
 .ico-direction:before {
   content: '\e844';
 }
 
 /* '' */
 .ico-compass:before {
   content: '\e845';
 }
 
 /* '' */
 .ico-cup:before {
   content: '\e846';
 }
 
 /* '' */
 .ico-trash:before {
   content: '\e847';
 }
 
 /* '' */
 .ico-doc:before {
   content: '\e848';
 }
 
 /* '' */
 .ico-docs:before {
   content: '\e849';
 }
 
 /* '' */
 .ico-doc-landscape:before {
   content: '\e84a';
 }
 
 /* '' */
 .ico-doc-text:before {
   content: '\e84b';
 }
 
 /* '' */
 .ico-doc-text-inv:before {
   content: '\e84c';
 }
 
 /* '' */
 .ico-newspaper:before {
   content: '\e84d';
 }
 
 /* '' */
 .ico-book-open:before {
   content: '\e84e';
 }
 
 /* '' */
 .ico-book:before {
   content: '\e84f';
 }
 
 /* '' */
 .ico-folder:before {
   content: '\e850';
 }
 
 /* '' */
 .ico-archive:before {
   content: '\e851';
 }
 
 /* '' */
 .ico-box:before {
   content: '\e852';
 }
 
 /* '' */
 .ico-rss:before {
   content: '\e853';
 }
 
 /* '' */
 .ico-phone:before {
   content: '\e854';
 }
 
 /* '' */
 .ico-cog:before {
   content: '\e855';
 }
 
 /* '' */
 .ico-tools:before {
   content: '\e856';
 }
 
 /* '' */
 .ico-share:before {
   content: '\e857';
 }
 
 /* '' */
 .ico-shareable:before {
   content: '\e858';
 }
 
 /* '' */
 .ico-basket:before {
   content: '\e859';
 }
 
 /* '' */
 .ico-bag:before {
   content: '\e85a';
 }
 
 /* '' */
 .ico-calendar:before {
   content: '\e85b';
 }
 
 /* '' */
 .ico-login:before {
   content: '\e85c';
 }
 
 /* '' */
 .ico-logout:before {
   content: '\e85d';
 }
 
 /* '' */
 .ico-mic:before {
   content: '\e85e';
 }
 
 /* '' */
 .ico-mute:before {
   content: '\e85f';
 }
 
 /* '' */
 .ico-sound:before {
   content: '\e860';
 }
 
 /* '' */
 .ico-volume:before {
   content: '\e861';
 }
 
 /* '' */
 .ico-clock:before {
   content: '\e862';
 }
 
 /* '' */
 .ico-hourglass:before {
   content: '\e863';
 }
 
 /* '' */
 .ico-lamp:before {
   content: '\e864';
 }
 
 /* '' */
 .ico-light-down:before {
   content: '\e865';
 }
 
 /* '' */
 .ico-light-up:before {
   content: '\e866';
 }
 
 /* '' */
 .ico-adjust:before {
   content: '\e867';
 }
 
 /* '' */
 .ico-block:before {
   content: '\e868';
 }
 
 /* '' */
 .ico-resize-full:before {
   content: '\e869';
 }
 
 /* '' */
 .ico-resize-small:before {
   content: '\e86a';
 }
 
 /* '' */
 .ico-popup:before {
   content: '\e86b';
 }
 
 /* '' */
 .ico-publish:before {
   content: '\e86c';
 }
 
 /* '' */
 .ico-window:before {
   content: '\e86d';
 }
 
 /* '' */
 .ico-arrow-combo:before {
   content: '\e86e';
 }
 
 /* '' */
 .ico-down-circled:before {
   content: '\e86f';
 }
 
 /* '' */
 .ico-left-circled:before {
   content: '\e870';
 }
 
 /* '' */
 .ico-right-circled:before {
   content: '\e871';
 }
 
 /* '' */
 .ico-up-circled:before {
   content: '\e872';
 }
 
 /* '' */
 .ico-down-open:before {
   content: '\e873';
 }
 
 /* '' */
 .ico-left-open:before {
   content: '\e874';
 }
 
 /* '' */
 .ico-right-open:before {
   content: '\e875';
 }
 
 /* '' */
 .ico-up-open:before {
   content: '\e876';
 }
 
 /* '' */
 .ico-down-open-mini:before {
   content: '\e877';
 }
 
 /* '' */
 .ico-left-open-mini:before {
   content: '\e878';
 }
 
 /* '' */
 .ico-right-open-mini:before {
   content: '\e879';
 }
 
 /* '' */
 .ico-up-open-mini:before {
   content: '\e87a';
 }
 
 /* '' */
 .ico-down-open-big:before {
   content: '\e87b';
 }
 
 /* '' */
 .ico-left-open-big:before {
   content: '\e87c';
 }
 
 /* '' */
 .ico-right-open-big:before {
   content: '\e87d';
 }
 
 /* '' */
 .ico-up-open-big:before {
   content: '\e87e';
 }
 
 /* '' */
 .ico-down:before {
   content: '\e87f';
 }
 
 /* '' */
 .ico-left:before {
   content: '\e880';
 }
 
 /* '' */
 .ico-right:before {
   content: '\e881';
 }
 
 /* '' */
 .ico-up:before {
   content: '\e882';
 }
 
 /* '' */
 .ico-down-dir:before {
   content: '\e883';
 }
 
 /* '' */
 .ico-left-dir:before {
   content: '\e884';
 }
 
 /* '' */
 .ico-right-dir:before {
   content: '\e885';
 }
 
 /* '' */
 .ico-up-dir:before {
   content: '\e886';
 }
 
 /* '' */
 .ico-down-bold:before {
   content: '\e887';
 }
 
 /* '' */
 .ico-left-bold:before {
   content: '\e888';
 }
 
 /* '' */
 .ico-right-bold:before {
   content: '\e889';
 }
 
 /* '' */
 .ico-up-bold:before {
   content: '\e88a';
 }
 
 /* '' */
 .ico-down-thin:before {
   content: '\e88b';
 }
 
 /* '' */
 .ico-left-thin:before {
   content: '\e88c';
 }
 
 /* '' */
 .ico-right-thin:before {
   content: '\e88d';
 }
 
 /* '' */
 .ico-up-thin:before {
   content: '\e88e';
 }
 
 /* '' */
 .ico-ccw:before {
   content: '\e88f';
 }
 
 /* '' */
 .ico-cw:before {
   content: '\e890';
 }
 
 /* '' */
 .ico-arrows-ccw:before {
   content: '\e891';
 }
 
 /* '' */
 .ico-level-down:before {
   content: '\e892';
 }
 
 /* '' */
 .ico-level-up:before {
   content: '\e893';
 }
 
 /* '' */
 .ico-shuffle:before {
   content: '\e894';
 }
 
 /* '' */
 .ico-loop:before {
   content: '\e895';
 }
 
 /* '' */
 .ico-switch:before {
   content: '\e896';
 }
 
 /* '' */
 .ico-play:before {
   content: '\e897';
 }
 
 /* '' */
 .ico-stop:before {
   content: '\e898';
 }
 
 /* '' */
 .ico-pause:before {
   content: '\e899';
 }
 
 /* '' */
 .ico-record:before {
   content: '\e89a';
 }
 
 /* '' */
 .ico-to-end:before {
   content: '\e89b';
 }
 
 /* '' */
 .ico-to-start:before {
   content: '\e89c';
 }
 
 /* '' */
 .ico-fast-forward:before {
   content: '\e89d';
 }
 
 /* '' */
 .ico-fast-backward:before {
   content: '\e89e';
 }
 
 /* '' */
 .ico-progress-0:before {
   content: '\e89f';
 }
 
 /* '' */
 .ico-progress-1:before {
   content: '\e8a0';
 }
 
 /* '' */
 .ico-progress-2:before {
   content: '\e8a1';
 }
 
 /* '' */
 .ico-progress-3:before {
   content: '\e8a2';
 }
 
 /* '' */
 .ico-target:before {
   content: '\e8a3';
 }
 
 /* '' */
 .ico-palette:before {
   content: '\e8a4';
 }
 
 /* '' */
 .ico-list:before {
   content: '\e8a5';
 }
 
 /* '' */
 .ico-list-add:before {
   content: '\e8a6';
 }
 
 /* '' */
 .ico-signal:before {
   content: '\e8a7';
 }
 
 /* '' */
 .ico-trophy:before {
   content: '\e8a8';
 }
 
 /* '' */
 .ico-battery:before {
   content: '\e8a9';
 }
 
 /* '' */
 .ico-back-in-time:before {
   content: '\e8aa';
 }
 
 /* '' */
 .ico-monitor:before {
   content: '\e8ab';
 }
 
 /* '' */
 .ico-mobile:before {
   content: '\e8ac';
 }
 
 /* '' */
 .ico-network:before {
   content: '\e8ad';
 }
 
 /* '' */
 .ico-cd:before {
   content: '\e8ae';
 }
 
 /* '' */
 .ico-inbox:before {
   content: '\e8af';
 }
 
 /* '' */
 .ico-install:before {
   content: '\e8b0';
 }
 
 /* '' */
 .ico-globe:before {
   content: '\e8b1';
 }
 
 /* '' */
 .ico-cloud:before {
   content: '\e8b2';
 }
 
 /* '' */
 .ico-cloud-thunder:before {
   content: '\e8b3';
 }
 
 /* '' */
 .ico-flash:before {
   content: '\e8b4';
 }
 
 /* '' */
 .ico-moon:before {
   content: '\e8b5';
 }
 
 /* '' */
 .ico-flight:before {
   content: '\e8b6';
 }
 
 /* '' */
 .ico-paper-plane:before {
   content: '\e8b7';
 }
 
 /* '' */
 .ico-leaf:before {
   content: '\e8b8';
 }
 
 /* '' */
 .ico-lifebuoy:before {
   content: '\e8b9';
 }
 
 /* '' */
 .ico-mouse:before {
   content: '\e8ba';
 }
 
 /* '' */
 .ico-briefcase:before {
   content: '\e8bb';
 }
 
 /* '' */
 .ico-suitcase:before {
   content: '\e8bc';
 }
 
 /* '' */
 .ico-dot:before {
   content: '\e8bd';
 }
 
 /* '' */
 .ico-dot-2:before {
   content: '\e8be';
 }
 
 /* '' */
 .ico-dot-3:before {
   content: '\e8bf';
 }
 
 /* '' */
 .ico-brush:before {
   content: '\e8c0';
 }
 
 /* '' */
 .ico-magnet:before {
   content: '\e8c1';
 }
 
 /* '' */
 .ico-infinity:before {
   content: '\e8c2';
 }
 
 /* '' */
 .ico-erase:before {
   content: '\e8c3';
 }
 
 /* '' */
 .ico-chart-pie:before {
   content: '\e8c4';
 }
 
 /* '' */
 .ico-chart-line:before {
   content: '\e8c5';
 }
 
 /* '' */
 .ico-chart-bar:before {
   content: '\e8c6';
 }
 
 /* '' */
 .ico-chart-area:before {
   content: '\e8c7';
 }
 
 /* '' */
 .ico-tape:before {
   content: '\e8c8';
 }
 
 /* '' */
 .ico-graduation-cap:before {
   content: '\e8c9';
 }
 
 /* '' */
 .ico-language:before {
   content: '\e8ca';
 }
 
 /* '' */
 .ico-ticket:before {
   content: '\e8cb';
 }
 
 /* '' */
 .ico-water:before {
   content: '\e8cc';
 }
 
 /* '' */
 .ico-droplet:before {
   content: '\e8cd';
 }
 
 /* '' */
 .ico-air:before {
   content: '\e8ce';
 }
 
 /* '' */
 .ico-credit-card:before {
   content: '\e8cf';
 }
 
 /* '' */
 .ico-floppy:before {
   content: '\e8d0';
 }
 
 /* '' */
 .ico-clipboard:before {
   content: '\e8d1';
 }
 
 /* '' */
 .ico-megaphone:before {
   content: '\e8d2';
 }
 
 /* '' */
 .ico-database:before {
   content: '\e8d3';
 }
 
 /* '' */
 .ico-drive:before {
   content: '\e8d4';
 }
 
 /* '' */
 .ico-bucket:before {
   content: '\e8d5';
 }
 
 /* '' */
 .ico-thermometer:before {
   content: '\e8d6';
 }
 
 /* '' */
 .ico-key:before {
   content: '\e8d7';
 }
 
 /* '' */
 .ico-flow-cascade:before {
   content: '\e8d8';
 }
 
 /* '' */
 .ico-flow-branch:before {
   content: '\e8d9';
 }
 
 /* '' */
 .ico-flow-tree:before {
   content: '\e8da';
 }
 
 /* '' */
 .ico-flow-line:before {
   content: '\e8db';
 }
 
 /* '' */
 .ico-flow-parallel:before {
   content: '\e8dc';
 }
 
 /* '' */
 .ico-rocket:before {
   content: '\e8dd';
 }
 
 /* '' */
 .ico-gauge:before {
   content: '\e8de';
 }
 
 /* '' */
 .ico-traffic-cone:before {
   content: '\e8df';
 }
 
 /* '' */
 .ico-cc:before {
   content: '\e8e0';
 }
 
 /* '' */
 .ico-cc-by:before {
   content: '\e8e1';
 }
 
 /* '' */
 .ico-cc-nc:before {
   content: '\e8e2';
 }
 
 /* '' */
 .ico-cc-nc-eu:before {
   content: '\e8e3';
 }
 
 /* '' */
 .ico-cc-nc-jp:before {
   content: '\e8e4';
 }
 
 /* '' */
 .ico-cc-sa:before {
   content: '\e8e5';
 }
 
 /* '' */
 .ico-cc-nd:before {
   content: '\e8e6';
 }
 
 /* '' */
 .ico-cc-pd:before {
   content: '\e8e7';
 }
 
 /* '' */
 .ico-cc-zero:before {
   content: '\e8e8';
 }
 
 /* '' */
 .ico-cc-share:before {
   content: '\e8e9';
 }
 
 /* '' */
 .ico-cc-remix:before {
   content: '\e8ea';
 }
 
 /* '' */
 .ico-github:before {
   content: '\e8eb';
 }
 
 /* '' */
 .ico-github-circled:before {
   content: '\e8ec';
 }
 
 /* '' */
 .ico-flickr:before {
   content: '\e8ed';
 }
 
 /* '' */
 .ico-flickr-circled:before {
   content: '\e8ee';
 }
 
 /* '' */
 .ico-vimeo:before {
   content: '\e8ef';
 }
 
 /* '' */
 .ico-vimeo-circled:before {
   content: '\e8f0';
 }
 
 /* '' */
 .ico-twitter:before {
   content: '\e8f1';
 }
 
 /* '' */
 .ico-twitter-circled:before {
   content: '\e8f2';
 }
 
 /* '' */
 .ico-facebook:before {
   content: '\e8f3';
 }
 
 /* '' */
 .ico-facebook-circled:before {
   content: '\e8f4';
 }
 
 /* '' */
 .ico-facebook-squared:before {
   content: '\e8f5';
 }
 
 /* '' */
 .ico-gplus:before {
   content: '\e8f6';
 }
 
 /* '' */
 .ico-gplus-circled:before {
   content: '\e8f7';
 }
 
 /* '' */
 .ico-pinterest:before {
   content: '\e8f8';
 }
 
 /* '' */
 .ico-pinterest-circled:before {
   content: '\e8f9';
 }
 
 /* '' */
 .ico-tumblr:before {
   content: '\e8fa';
 }
 
 /* '' */
 .ico-tumblr-circled:before {
   content: '\e8fb';
 }
 
 /* '' */
 .ico-linkedin:before {
   content: '\e8fc';
 }
 
 /* '' */
 .ico-linkedin-circled:before {
   content: '\e8fd';
 }
 
 /* '' */
 .ico-dribbble:before {
   content: '\e8fe';
 }
 
 /* '' */
 .ico-dribbble-circled:before {
   content: '\e8ff';
 }
 
 /* '' */
 .ico-stumbleupon:before {
   content: '\e900';
 }
 
 /* '' */
 .ico-stumbleupon-circled:before {
   content: '\e901';
 }
 
 /* '' */
 .ico-lastfm:before {
   content: '\e902';
 }
 
 /* '' */
 .ico-lastfm-circled:before {
   content: '\e903';
 }
 
 /* '' */
 .ico-rdio:before {
   content: '\e904';
 }
 
 /* '' */
 .ico-rdio-circled:before {
   content: '\e905';
 }
 
 /* '' */
 .ico-spotify:before {
   content: '\e906';
 }
 
 /* '' */
 .ico-spotify-circled:before {
   content: '\e907';
 }
 
 /* '' */
 .ico-qq:before {
   content: '\e908';
 }
 
 /* '' */
 .ico-instagram:before {
   content: '\e909';
 }
 
 /* '' */
 .ico-dropbox:before {
   content: '\e90a';
 }
 
 /* '' */
 .ico-evernote:before {
   content: '\e90b';
 }
 
 /* '' */
 .ico-flattr:before {
   content: '\e90c';
 }
 
 /* '' */
 .ico-skype:before {
   content: '\e90d';
 }
 
 /* '' */
 .ico-skype-circled:before {
   content: '\e90e';
 }
 
 /* '' */
 .ico-renren:before {
   content: '\e90f';
 }
 
 /* '' */
 .ico-sina-weibo:before {
   content: '\e910';
 }
 
 /* '' */
 .ico-paypal:before {
   content: '\e911';
 }
 
 /* '' */
 .ico-picasa:before {
   content: '\e912';
 }
 
 /* '' */
 .ico-soundcloud:before {
   content: '\e913';
 }
 
 /* '' */
 .ico-mixi:before {
   content: '\e914';
 }
 
 /* '' */
 .ico-behance:before {
   content: '\e915';
 }
 
 /* '' */
 .ico-google-circles:before {
   content: '\e916';
 }
 
 /* '' */
 .ico-vkontakte:before {
   content: '\e917';
 }
 
 /* '' */
 .ico-smashing:before {
   content: '\e918';
 }
 
 /* '' */
 .ico-sweden:before {
   content: '\e919';
 }
 
 /* '' */
 .ico-db-shape:before {
   content: '\e91a';
 }
 
 /* '' */
 .ico-logo-db:before {
   content: '\e91b';
 }
 
 /* '' */
 table {
   width: 100%;
   border: 0;
   xborder-collapse: separate;
   font-size: 12px;
   text-align: left;
 }
 
 thead {
   /* background-color: #f5f5f4; */
 }
 
 tbody {
   xbackground-color: #fff;
 }
 
 .table-striped tr:nth-child(even) {
   background-color: #f5f5f4;
 }
 
 tr:active,
 .table-striped tr:active:nth-child(even) {
   /* color: #fff;
   background-color: #116cd6; */
 }
 
 thead tr:active {
   /* color: #333;
   background-color: #f5f5f4; */
 }
 
 th {
   font-weight: normal;
   border-right: 1px solid #ddd;
   border-bottom: 1px solid #ddd;
 }
 
 th,
 td {
   /* padding: 2px 15px; */
   xwhite-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 th:last-child,
 td:last-child {
   border-right: 0;
 }
 
 .tab-group {
   margin-top: -1px;
   display: flex;
   border-top: 1px solid #989698;
   border-bottom: 1px solid #989698;
 }
 
 .tab-item {
   position: relative;
   flex: 1;
   padding: 3px;
   font-size: 12px;
   text-align: center;
   border-left: 1px solid #989698;
   background-color: #b8b6b8;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b8b6b8), color-stop(100%, #b0aeb0));
   background-image: -webkit-linear-gradient(top, #b8b6b8 0%, #b0aeb0 100%);
   background-image: linear-gradient(to bottom, #b8b6b8 0%, #b0aeb0 100%);
 }
 .tab-item:first-child {
   border-left: 0;
 }
 .tab-item.active {
   background-color: #d4d2d4;
   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d4d2d4), color-stop(100%, #cccacc));
   background-image: -webkit-linear-gradient(top, #d4d2d4 0%, #cccacc 100%);
   background-image: linear-gradient(to bottom, #d4d2d4 0%, #cccacc 100%);
 }
 .tab-item .ico-close-tab {
   position: absolute;
   top: 50%;
   left: 5px;
   width: 15px;
   height: 15px;
   font-size: 15px;
   line-height: 15px;
   text-align: center;
   color: #666;
   opacity: 0;
   transition: opacity .1s linear, background-color .1s linear;
   border-radius: 3px;
   transform: translateY(-50%);
   z-index: 10;
 }
 .tab-item:after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   content: "";
   background-color: rgba(0, 0, 0, 0.08);
   opacity: 0;
   transition: opacity .1s linear;
   z-index: 1;
 }
 .tab-item:hover:not(.active):after {
   opacity: 1;
 }
 .tab-item:hover .ico-close-tab {
   opacity: 1;
 }
 .tab-item .ico-close-tab:hover {
   background-color: rgba(0, 0, 0, 0.08);
 }
 
 .tab-item-fixed {
   flex: none;
   padding: 3px 10px;
 }
 