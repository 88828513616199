

/* @media print{ body{ -webkit-print-color-adjust: exact; } } */
.sCel * {
  pointer-events: none;
 }

.weekPickerBg{ background-color:#D4D4D4;}
.weekPickerBgOne { 
  border-radius: 10px;
 background-color:#D4D4D4;
 color:#fff;
}
.weekPickerBgStart { 
   border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color:#D4D4D4;

}
.weekPickerBgStop{ 
   border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color:#D4D4D4;
 
}
.weekPickerBg b , .weekPickerBgOne b ,  .weekPickerBgStart b , .weekPickerBgStop b{ 
   color:rgba(255, 255, 255,1)!important;
}

.scheduleDivider h4{font-weight:bold!important;}
.scheduleDivider{
  xposition: relative;
  
  /* background-color: rgba(192,192,192,0.1)!important;  */
  border-bottom: solid 0px #f1f1f1!important;
  border-top: solid 0px #f1f1f1!important;
  
  height:10px!important;
  overflow:hidden!important;
  padding:0px!important;
  padding-top:20px!important;
  
}



.divUsername  img {
  touch-action: none; 
  width: 30px!important; 
  height: 30px!important; 
  border-radius: 15px;
  width: 12.5%
}

.divUsername > div {
  display: inline;
  margin: 0px;
  padding: 0px;
  touch-action: none;
  font-size: 14px;
   font-weight: bold;}

.sCel{
  display: inline-block !important;
  -webkit-user-drag:element;
  pointer-events: auto;
}


.sCel{
  flex-grow:1;
  padding:4px!important; 
  margin:3px!important;
  margin-bottom:4px!important;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 3px!important;
  margin-top: 0px;
}
.sCel > b{
  display:block;
  line-height:normal;
}
.sCel > div{
  color:var(--cell-color-text);
  font-size: 13px!important;
  line-height:normal;
}
.sCel > span{
  font-size: 11px!important;
  font-weight: 400;
  white-space: nowrap;
}




.scheduelCell{ 
  height: 30px;
  border-left:dotted 1px var(--color-border);
  padding:1px!important; 
  xposition:relative;
   

}


.sCellName{ 
  height: 30px;
  border-left:dotted 1px var(--color-border);
  padding:1px!important; 
  position:relative;
  width: 16%;
  border-left: none;

    

}


.stripes{ 
  
  background:repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.02),
    rgba(0, 0, 0, 0.02) 5px,
    rgba(0, 0, 0, 0.05) 1px,
    rgba(0, 0, 0, 0.05) 10px)!important
}

.scheduelCell {
  width: 12.5%;
  
}

 .scheduelCell > .addShiftBtn{
  position:absolute;
  top:2px;
  right:2px;
  /* z-index:99; */
  
 }
 .scheduelCell > .flexDiv{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  xpointer-events: none;
 }

 .schedualUsername
 {-webkit-user-drag:element;}
 

 
 .schedualUsername2 img{ 
  float: left; 
  margin-right: 8px;
  margin-bottom: 0px;
  margin-top: 0px;
   
  
 }
 .schedualUsername2 div{ 
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
   padding-top: 10px; 
   font-weight: normal;
   white-space: nowrap;
 }
 

.schedualUsername img{ 
  float: left; 
  margin-right: 8px;
  margin-bottom: 0px;
  margin-top: 0px;
  cursor: grab;
  
  
 }
 .schedualUsername div{ 
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
   padding-top: 10px; 
   font-weight: normal;
   white-space: nowrap;
 }
 
 .schedualUsernameUnassing
 { }
 .schedualUsernameUnassing div{ 
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
   padding-top: 6px; 
   font-weight: normal;
   white-space: nowrap;
   font-weight: bold;
 }




 .cellUsername{
  background-color: var(--color-bg1)!important;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  padding:0px!important;
  position: relative;
} 
.cellUsername > img{
 margin:4px; 
 
}

.topMenuWeekDateDiv{
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
 
}

 
.topMenuWeekDateMainDivTools{
  padding:10px;
  cursor: pointer;
  margin: 0px
}
.topMenuWeekDateMainDivTools >*{
  cursor: pointer!important;

}

 

.topMenuWeekDateMainDiv{
  cursor: pointer; 
  min-width: 135px;
  width:200px;
  text-align: center; 
  color: gray;
  transition: all 0.3s ease;
  
}
.topMenuWeekDateMainWeek{
  font-size: 20px;
font-weight: normal;
font-family: Raleway!important;

}
.topMenuWeekDateMainB{
  
  /* min-width: 200px; */
  font-size: 28px;
  padding-top: 0px;
  font-family: Raleway!important;

  /* xcolor:deepskyblue; */
  /* xfont-weight: 900; */
  
}


.scheduelHeaderCell{ 
  line-height: 1.1;
  margin-bottom: 10px;
  font-weight: bold!important;
  color:var(--color-text-header)!important;
  text-align: center!important;
  background-color: var(--color-bg1)!important;
  vertical-align: bottom!important;
  padding:0px!important;
  padding-bottom:2px!important; 
  padding-top:2px!important;
  border-left:solid 1px var(--color-bg1)!important;
  border-right:solid 1px var(--color-bg1);
  font-size: 11px!important;

}
.scheduelHeaderCell span{ 
  line-height: 1.1;
  margin-bottom: 10px;
  font-weight: bold!important;
  text-align: center!important;
  background-color: var(--color-bg1)!important;
  vertical-align: bottom!important;
  padding:0px!important;
  padding-bottom:2px!important; 
  padding-top:2px!important;
  border-left:solid 1px var(--color-bg1)!important;
  border-right:solid 1px var(--color-bg1);
  font-size: 11px!important;

}

/*small screen*/
@media only screen and (max-width:770px) {

 
  .sCel > b{
    font-size: 9px!important;
  
  }
  .sCel > div{
    font-size: 9px!important;
    line-height:normal;
  }
  .sCel > span{
    font-size: 9px!important;
  
  }
  
  .scheduelHeaderCell{ 
    font-size: 9px!important;
    
  }
  .scheduelHeaderCell span{ 
    font-size: 9px!important;
  }



   .schedualUsername div{
    font-size: 11px!important;
    
  }
    .topMenuWeekDateDiv{
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
   
  }
  .topMenuWeekDateMainDiv{
    cursor: pointer; 
    min-width: 70px;
    width:135px;
    text-align: center; 
    color: gray;
    transition: all 0.3s ease;
    
  }

  


  .cellUsername > img{
    /* margin:0px; 
    padding:0px; 
    margin-top:2px; 
    width: 42px!important ; */

  }


  .cellMulti{
    flex-grow:1;
    padding:6px!important; 
    margin:1px!important;
    /* width:calc(100% -2px) */
  }

  .cellMulti > div{
    font-size: 10px!important;
    line-height:normal;
  }
  .cellMulti > span{
    font-size: 9px!important;
    font-weight: 400;
  }

 

 
}