.redux-toastr .toastr.rrt-success {
  background-color:  rgba(34, 36, 38, 0.85)!important;
}
.redux-toastr .toastr .rrt-middle-container {
  font-size: 12px!important;
 }
.redux-toastr .toastr .rrt-left-container .toastr-icon{
  width: 20px!important;
  height: 20px!important;
 }

.redux-toastr .toastr {
 
  min-height: 50px!important;
  line-height:13px!important;
  /* opacity: 0.8!important;
  -webkit-transform: translate3d(0, 0, 0); 
  transform: translate3d(0, 0, 0);  */
}   

.rrt-title{ color:#fff!important;}
.rrt-text{ color:#fff!important;}