
 .ui.table tr td.dataTable{ 
  border-top:none!important;
  padding:0.48571429em 0.78571429em !important;
}


.ui.table thead th.dataTable {
  cursor: auto;
  xbackground: #F9FAFB;
  text-align: inherit;
  xcolor: rgba(0, 0, 0, 0.87);
  background-color: var(--color-bg1);
  color: var(--color-font);
  padding:0.48571429em 0.78571429em !important;
  vertical-align: inherit;
  font-style: none;
  font-weight: bold;
  text-transform: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-left: none;
  border-right: none;
  overflow: hidden;
}

.ui.table thead.dataTable {
  display: block!important;
}

.ui.table tbody.dataTable  {
  overflow-y: scroll; 
  display: block;   
 
}

/* .ui.dataTable  table {
 background-color: red!important;
 
} */