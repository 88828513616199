
#context-menu{
    background: #fff;
    border-radius: 5px;
    padding:3px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    position: absolute;
    z-index: 10000;

}

#context-menu  > div:hover{background-color: rgba(0,0,0,0.08)!important;}
/* #context-menu  > div{border:solid 1px red;} */