#menu-toggle0:checked + #menu0 {height: initial;opacity: 1;}
#menu0{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle1:checked + #menu1 {height: initial;opacity: 1;}
#menu1{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle2:checked + #menu2 {height: initial;opacity: 1;}
#menu2{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle3:checked + #menu3 {height: initial;opacity: 1;}
#menu3{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle4:checked + #menu4 {height: initial;opacity: 1;}
#menu4{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle5:checked + #menu5 {height: initial;opacity: 1;}
#menu5{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle6:checked + #menu6 {height: initial;opacity: 1;}
#menu6{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle7:checked + #menu7 {height: initial;opacity: 1;}
#menu7{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle8:checked + #menu8 {height: initial;opacity: 1;}
#menu8{height: 0px;overflow: hidden;opacity: 0;}

#menu-toggle9:checked + #menu9 {height: initial;opacity: 1;}
#menu9{height: 0px;overflow: hidden;opacity: 0;}
.webMenuMain{
    height: 34px; 
    background:#fff; 
    padding-top: 4px;
    display: flex; 
    z-index: 1000; 
    font-family: 'arial'; 
    position: fixed; 
    left: 0;
    right: 0;
    top:0;
    padding-left: 5px;
    /* //border-top: 0px dotted rgba(1, 1, 1, 0.07); */
    border-bottom: 1px dotted rgba(1, 1, 1, 0.07);
}
.webMenuMain > div{
    border-radius: 4px;
    padding-left:3px;
    padding-right:0px;
    padding-top:1px;
    padding-bottom:1px;
    height: 25px;
}
.webMenuMain > div:hover{background-color: rgba(0,0,0,0.08)!important;}
.webMenuContainer{
     background: #fff;
     height: 30px;
     border-radius: 5px;
     padding:3px;
     box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
     margin-top: -22px;
     position: absolute;
     
}

.webMenuCanClick:hover{
    background:rgba(255,255,255,0.4);
    
}