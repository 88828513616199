
@media print {
    
    html,body,table{
        margin:0;
        padding:0;
    }
    .redux-toastr{
        display: none!important;
    }
    #submain{background-color: transparent!important;}
    @page  
    { 
        size: landscape; 
        /* this affects the margin in the printer settings */ 
         margin-bottom: 10mm; 
       margin-left: 10mm; 
        margin-right: 4mm;  
        margin-top: 10mm;  
        /* size: auto; */
        
        /* widows: 2; */
    }
    /* @page :first {
        margin: 10mm 10mm 10mm 10mm!important;
    } */

    table { page-break-after:auto;bottom:0px;position: absolute;top:0px;width:100vw!important;border:none!important;}
    tr    { page-break-inside:avoid; page-break-after:auto ;border:none!important;}
    td    { page-break-inside:avoid; page-break-after:auto ;}
    
    

    thead {
        display:table-row-group;
    }  
    /* tr:first-child {
        background: yellow!important;
        padding-top:40px!important;
        height: 300px;
    } */

     .tblSchedualHead{
        position: relative!important;
        display:table-header-group
    } 
    .menuLandscape {
        display:none!important;
      }
    body.landscape div.main {
        padding-left: 0px!important;
    
    }
    .noPrint{display:none!important;}

    /* @page :not(:first) {
        margin-top:250px;
    } */
}
