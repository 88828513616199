
 
body.mobile {
  
}


body.mobile .ui.modal>.header{
  border-bottom:none!important;
  padding-right:14px!important;
}
 
  body.mobile .modal .header{
    border-top-right-radius: 0px!important;
    border-top-left-radius: 0px!important;
  }
  body.mobile .modal .actions{
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    
  }

  body.mobile .modal{
    background-color: #fff!important;
    border-radius: 0px!important;
    width: 100%!important;
    
    /* overflow: hidden; */
    top: 0px!important;
    margin: 0px!important;
    left:0px!important;
    right:0px!important;
    bottom:0px!important;
    height: 100%!important;
    position: fixed; 
    /* border:solid 1px green; */
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    
  }
 /* i.icon, i.icons {
    font-size: 20px!important;
} */

body.mobile .ui.menu .ui.dropdown .menu>.item .icon:not(.dropdown) {
    display: inline-block;
    font-size: 25px!important;
    float: none;
    margin: 0 .75em 0 0!important;
}

body.mobile .ui.cards>.card {
    width: 100%!important;
}


