.tHeadPrint {
  /* padding-top: 40px; */
}

@media screen {
  div.divFooter,
  div.divPrintHeader {
    display: none;
  }
}

@media print {
  .tblPrintTable {
    page-break-inside: avoid !important;
    page-break-after: always !important;
  }
  :root {
    --color-btn-red: #db2828;
    --color-btn-red-a: #b21e1e;
    --color-btn-red-h: #d01919;
    --color-btn-green: #21ba45;
    --color-btn-green-a: #198f35;
    --color-btn-green-h: #16ab39;
    --color-btn-blue: #2185d0;
    --color-btn-blue-h: #1678c2;
    --color-btn-blue-a: #1a69a4;
    --color-btn-gray: #e0e1e2;
    --color-btn-gray-h: #cacbcd;
    --color-btn-gray-a: #babbbc;

    --color-xred: rgba(219, 40, 40, 0.1);
    --color-xorange: rgba(242, 113, 28, 0.1);
    --color-xyellow: rgba(251, 189, 8, 0.1);
    --color-xolive: rgba(181, 204, 24, 0.1);
    --color-xgreen: rgba(33, 186, 69, 0.1);
    --color-xteal: rgba(2, 195, 154, 0.1);
    --color-xblue: rgba(33, 133, 208, 0.1);
    --color-xviolet: rgba(100, 53, 201, 0.1);
    --color-xpurple: rgba(163, 51, 200, 0.1);
    --color-xpink: rgba(224, 57, 151, 0.1);
    --color-xbrown: rgba(165, 103, 63, 0.1);
    --color-xgrey: rgba(118, 118, 118, 0.1);
    --color-xblack: rgba(27, 28, 29, 0.1);

    --color-bg1: #fff;
    --color-bg2: rgba(240, 240, 240, 1);
    --color-menu-noselected: rgba(56, 56, 56, 0.8);
    --color-menu-selected: black;
    --color-font: rgba(0, 0, 0, 0.87);
    --cell-color-text: #434242;
    --color-menu-icon: rgba(0, 0, 0, 0.87);
    --color-text-header: gray;
    --color-bg-dropdown: #f5f5f5;
    --menuLandscapeborder: rgba(34, 36, 38, 0.15);
    --inputbg: #fff;
    --pointer: rgba(34, 36, 38, 0.1);
    --color-border: rgba(34, 36, 38, 0.15);
    --color-border2: rgba(34, 36, 38, 0.1);
    --color-border-menu: rgba(34, 36, 38, 0.15);
  }

  div.divFooter {
    position: fixed;
    bottom: -5px;
    right: 10px;
    color: #212121;
  }
  div.divPrintHeader {
    position: fixed;
    top: -5px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    z-index: 100000;
    background-color: rgba(255, 255, 255, 0.6);
  }

  body {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 1cm;
    margin-right: 1cm;
  }

  html,
  body {
    height: auto;
  }
  /* .schedulePrint{ margin: 40px } */
  .printHidden {
    display: none;
  }
  .printShow {
    display: block;
  }
  .scheduelCell {
    /* border:solid 1px red!important; */
    /* border-width:0px 1px 1px 0px !important; */
  }

  .tblBreakerNoHeader {
    margin-top: 50px !important;
  }

  table {
    display: block;
    page-break-inside: auto;
    border: solid 1px #f1f1f1 !important;
    border-width: 1px 1px 1px 1px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  tr {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
    border: solid 0px green !important;
    border-width: 0px 0px 0px 0px !important;
  }

  .scheduleDivider {
    border: solid #888 !important;
    border-width: 0px 0px 1px 0px !important;
    /* box-shadow: inset 0 0 0 1000px #f1f1f1; */
    /* page-break-after: always!important; */
  }

  th,
  td {
    border: solid 1px #f1f1f1 !important;
    border-width: 0px 1px 1px 0px !important;
  }
  /* -- This brakes printing on some printers -- */
  /*   
  th, td:last-child
  {
    border:solid 1px #f1f1f1!important;
    border-width:0px 0px 1px 0px !important;
  }
  tr:last-child td
  {
    border:solid 1px #f1f1f1!important;
    border-width:0px 1px 0px 0px !important;
    
  }
  tr:last-child 
  {
    margin-bottom: 10px;
  }
  tr:last-child td:last-child
  {
    border:solid 1px #f1f1f1!important;
    border-width:0px 0px 0px 0px !important;
  }   */
}
