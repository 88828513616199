.ticks {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  
}
.tick {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px;
  background: rgba(158, 158, 158, 0.21);
  height:30px;
  line-height: 5px;
  margin-bottom: 1px;
  font-size: 10px;
  color:var(--color-font);
}


.tick1 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 0px;
  background: silver;
  height:10px;
  
  line-height: 5px;
  margin-bottom: 1px;
}


  .ui.menu .active.item:hover{
    border-radius: 0px!important;
  }



.divSchedual1{
   width: 100%; 

   padding-left: 10px;  
    xpadding-top: 49px;  
   
}

.lblLastChange{
  color:var(--color-font)!important;
  position: fixed;
  bottom: 0px; 
  right: 0px; 
  background-color:var(--color-bg2)!important;
}




 
.sMenu_s1 { border: none; xposition: fixed; z-index: 10;xbackground: #fff!important;xheight: 50px}
.sMenu_s2 { border: none;  xheight: 30px; min-width: 350px!important;display:flex;}
.sMenu_s3 { display: inline;font-size: 20px; text-overflow: ellipsis;max-width:500px;overflow: hidden;}
.sMenu_s4 {margin-left: auto!important}
.sMenu_s5 {}

/* mac os
 body.landscape .sMenu_s1{padding-right: 68px!important;} */

.sRowTbl{
  user-select: none;
  border: solid 1px transparent;
  border-radius: 3px;
  xpointer-events: none;
  }

.sRowTblAddRow{
  user-select: none;
  border: solid 1px transparent;
  

}
.sRowTbl:hover{
  
  border-bottom: solid 1px silver!important;
  border-top: solid 1px silver!important;

  background-color: rgba(158, 158, 158, 0.06);
}

.sRowCell{
  xposition: relative; 
  padding: 0px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  
}


.mobile .sRowCellLbl{
  padding: 0px!important;
}

.sRowCellLbl{
  
    position: absolute;
    top: 0px;
    right: 2px;
    /* z-index: 1; */
    font-size: 10px;
     /* xbackground-color: #f1f1f1; */
    border-radius: 10px;
    padding: 4px;
    line-height: 10px;
    text-align: center;
    font-weight: bold;
    /* opacity:0.9; */
  
}
.sRowCellHeader{
    font-weight: normal;
    cursor: grab; 
    color:var(--color-font)!important;
  }

  /* Schedule row end */