
@font-face{
	font-family:"Raleway";
        src: url(./Raleway-Regular.ttf)  format("truetype");
}

@font-face{
	font-family:"Raleway";
        src: url(./Raleway-Bold.ttf)  
        format("truetype");
        font-weight: bold;
}

 